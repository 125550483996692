import {z} from 'zod';
import {OnboardingStep} from '@app/features/onboarding';
import {QueryKey} from '@app/misc/query-state';

const normalQueryStateQuery = z.object({
  // [QueryKey.STATE]: z.literal('n').optional(),
  [QueryKey.REFERRER]: z.enum(['m', 'signup', 'login', 'diagnosis']).optional(),
});
const onboardingQueryStateQuery = z.object({
  // [QueryKey.STATE]: z.literal('o').optional(),
  [QueryKey.DID_DIAGNOSIS_BEFORE_SIGNUP]: z.enum(['0', '1']).optional(),
});
const b2bQueryStateQuery = z.object({
  // [QueryKey.STATE]: z.literal('b').optional(),
  [QueryKey.BOARD_CODE]: z.string().optional(),
  [QueryKey.PRODUCT_ID]: z.string().optional(),
});
const redirectionQueryStateQuery = z.object({
  // [QueryKey.STATE]: z.literal('r').optional(),
  [QueryKey.REDIRECT_TO]: z.string().optional(),
});
const queryStateQuery = z
  .union([onboardingQueryStateQuery, b2bQueryStateQuery, redirectionQueryStateQuery, normalQueryStateQuery])
  .and(z.object({[QueryKey.STATE]: z.enum(['n', 'o', 'b', 'r']).optional()}));

const booleanQuery = z.string().transform(value => value.toLocaleLowerCase() === 'true');

const orderCommonQuery = z.object({
  orderId: z.coerce.number(),
});
const subscriptionOrderCommonQuery = z.object({
  subscriptionOrderId: z.coerce.number(),
});
const stripeSubscriptionCommonQuery = z.object({
  subscriptionId: z.coerce.number(),
});
const courseCommonQuery = z.object({
  // for https://www.figma.com/design/mgJhxCrrwFtTnTItXd3ylX/%F0%9F%A6%84%E2%9A%A1%EF%B8%8F-2024_Santa-1toN_UI-Design_iOS%2BAOS%2BWEB?node-id=3427-164653&t=Yflo60nI9Ud6Iqou-1
  ticketCount: z.coerce.number().optional(),
  // for https://www.figma.com/design/mgJhxCrrwFtTnTItXd3ylX/%F0%9F%A6%84%E2%9A%A1%EF%B8%8F-2024_Santa-1toN_UI-Design_iOS%2BAOS%2BWEB?node-id=6992-197814&m=dev
  showCurriculumToast: booleanQuery.optional(),
  /**
   * 사이클 상세페이지에서 마케팅 바텀시트를 띄우기 위해 사용.
   * 용처: 커리큘럼 강의셀에서 건너뛸때 권한 없으면 이 값 true로 사이클 페이지로 보냄
   */
  disallowed: booleanQuery.optional(),
});
export const offerGroupReferrerQuery = z.object({
  /**
   * 딥링크: deep_link
   * 상단 네브바 (티켓) : top_nav_bar
   * 내 정보 “모든 상품 보러가기” : setting_seeallproduct
   * 내 정보 이용권 컴포넌트 : setting_authority
   * 마케팅 팝업 바텀시트 : mkt_bottomsheet
   * 추천학습 결제유도 바텀시트: unavailable_cell
   * 선택학습 결제유도 바텀시트(모의고사 제외): unavailable_self_learning
   * 선택학습 모의고사 결제유도 바텀시트: unavailable_self_learning_virtual_exam
   * 복습 퀴즈 결제 유도 바텀시트: unavailable_review_quiz
   * flash deal 페이지 offer group 카드 : flash_deal
   * L2E 상세 페이지 (상품 구매하고 포인트 모으기) : l2e_detail
   *
   * 700+ 코스 결제유도 바텀시트 (figma) : unavailable_cell_course_700
   * 700+ 코스 져니 > 권한 없은 사이클 카드 (figma) : unavailable_cycle_course_700
   * 700+ 사이클 결과 > offer group 카드 (figma) : cycle_result_course_700
   * 700+ 사이클 결과 > {다음 사이클 명} 학습 시작하기 *권한이 없는 경우(figma) :  gotonextcycle_course_700
   *
   * ref: https://www.notion.so/riiid/referrer-offer_group_board-12a5bc5f6307800cb135ff18c06636ea?pvs=4
   */
  referrer: z
    .enum([
      'deep_link',
      'top_nav_bar',
      'setting_seeallproduct',
      'setting_authority',
      'mkt_bottomsheet',
      'unavailable_cell',
      'unavailable_self_learning',
      'unavailable_self_learning_virtual_exam',
      'unavailable_review_quiz',
      'flash_deal',
      'l2e_detail',
      'unavailable_cell_course_700',
      'unavailable_cycle_course_700',
      'cycle_result_course_700',
      'gotonextcycle_course_700',
    ])
    .or(z.string())
    .optional(),
});

const offerOrderCommonQuery = z
  .object({
    id: z.coerce.number(),
    offerId: z.coerce.number().optional(),
  })
  .and(offerGroupReferrerQuery);
const offerGroupCommonQuery = z
  .object({
    /**
     * 보드 - 오퍼그룹 - 오퍼의 위계를 가짐.
     * boardCode : 명시하지 않을 경우, default 오퍼그룹으로 이동
     * ex) "FLASH" - 플래시딜 관련 보드코드
     */
    boardCode: z.string().optional(),
  })
  .and(offerGroupReferrerQuery);

const highlightColorFilterUnion = z.enum(['BRAND', 'RIIID_RED', 'RIIID_PURPLE', 'RIIID_YELLOW', 'RIIID_GREEN']);

const TOEIC_SPEAKING_BASE_PATH = '/toeic-speaking' as const;

export const TOEIC_SPEAKING_TYPED_QUERY_METADATA = {
  [TOEIC_SPEAKING_BASE_PATH]: z.object({}),
  // GNB menu related
  [`${TOEIC_SPEAKING_BASE_PATH}/main` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/exam` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/faq` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/me` as const]: z.object({}),
  // ---
  [`${TOEIC_SPEAKING_BASE_PATH}/ai/prediction-score` as const]: z.object({}),
  // me page와 UI 공유
  [`${TOEIC_SPEAKING_BASE_PATH}/product/board` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/support` as const]: z.object({}),
  // ---
  [`${TOEIC_SPEAKING_BASE_PATH}/order` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/order/complete` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/order/success` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/order/failure` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/order/detail` as const]: z.object({}),
  // ---
  [`${TOEIC_SPEAKING_BASE_PATH}/report` as const]: z.object({
    examId: z.coerce.number(),
  }),
  [`${TOEIC_SPEAKING_BASE_PATH}/results` as const]: z.object({
    examId: z.coerce.number(),
    resultIndex: z.coerce.number().default(0),
  }),
  // ---
  [`${TOEIC_SPEAKING_BASE_PATH}/onboarding` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/signup` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/signup/sns` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/signup/email` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/login` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/login/email` as const]: z.object({}),
  // ---
  [`${TOEIC_SPEAKING_BASE_PATH}/coupon` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/coupon/detail/exchange` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/event` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/pc-web` as const]: z.object({}),
  [`${TOEIC_SPEAKING_BASE_PATH}/test/shell` as const]: z.object({}),
} as const;

/**
 * @description useTypedRouter, useTypedSearchParam, TypedLink에서 사용하는 router 메타데이터
 *
 * NOTE:
 * - 각 param은 string, string literal, boolean인 경우를 제외하면 반드시 z.coerce를 사용해야 함.
 *   router.query의 map value 값은 string/string[] 인데, 그냥 z.{다른 타입}()을 이용하면 parse할 때 string <-> {해당 타입}간 타입 불일치로 인해 zod 에러 발생
 * - z.object의 각 필드는 기본적으로 required이므로, optional param에는 반드시 .optional()을 붙여야 함.
 *   그렇지 않으면 useTypedSearchParam을 이용했을 때 해당 필드가 없어 에러가 발생함.
 *   - default value가 있는 optional의 경우 .optional()을 default보다 나중에 호출하도록 해야 undefined를 받을 수 있음.
 * - array 타입의 query param이 필요한 경우의 사용법
 *   - array만 활용하는 경우 z.array(z.coerce.number())
 *   - 단일 타입과 함께 활용하는 경우 z.coerce.number().or(z.array(z.coerce.number()))
 *     - arrayParam 함수 사용하세요 ex. arrayParam(z.coerce.number())
 * - refine을 이용하면 세부적인 조건 설정도 가능. ref: https://zod.dev/?id=refine
 * - string으로 들어오는 boolean타입 사용시 booleanQuery 타입을 사용해주세요. coerce.boolean()은 들어온 값이 truthy인지 falsy인지 판단합니다.
 */
export const TYPED_QUERY_METADATA = {
  '/': queryStateQuery,
  '/404': z.object({}),
  '/500': z.object({}),
  '/ai/deleted-question': z.object({}),
  '/ai/knowledge-tracing': z.object({}),
  '/ai/prediction-score': z.object({}),
  '/ai/prediction-score/lack-of-data': z.object({}),
  '/ai/score-diff-santatoeic': z.object({}),
  '/ai/skill-change': z.object({}),
  '/analytics': z.object({}),
  '/analytics/skill': z.object({}),
  '/analytics/statistics': z.object({
    /**
     * Scroll to detail (UpperArea, LowerArea)
     */
    focus: z.enum(['upper', 'lower']).optional(),
    /**
     * Tab
     */
    tab: z.enum(['day', 'week', 'month']).optional(),
    /**
     * Dates (day, week, month, year)
     */
    day: z.coerce.number().optional(),
    week: z.coerce.number().optional(),
    month: z.coerce.number().optional(),
    year: z.coerce.number().optional(),
  }),
  '/app-download': z.object({
    signUpCompleted: booleanQuery.optional(),
  }),
  '/app-update': z.object({}),
  '/content-interaction-state': z.object({
    contentInteractionStateId: z.coerce.number(),
    /**
     * Whether the page is from previous page
     *
     * (If have the prev one, a close button in this page will work as back button)
     */
    hasPreviousPage: booleanQuery.optional(),
    referrer: z.enum(['curriculum']).optional(),
  }),
  '/content-interaction-states': z.object({
    cisIds: z.coerce
      .number()
      .or(z.array(z.coerce.number()))
      .transform(value => (Array.isArray(value) ? value : [value])),
    currentCisIndex: z.coerce.number().optional(),
    from: z.enum(['web', 'mobile']).optional(),
  }),
  '/coupon': z.object({
    code: z.string().optional(),
  }),
  '/course': courseCommonQuery,
  '/course/[courseId]/[cycleId]': courseCommonQuery.and(
    z.object({
      ordinalNumber: z.coerce.number().optional(),
      courseId: z.coerce.number(),
      cycleId: z.enum(['test', 'now']).or(z.coerce.number()),
    })
  ),
  '/course/list': z.object({
    currentCycleId: z.coerce.number().optional(),
  }),
  '/course/learning-cell': z.object({}),
  '/course/report': z.object({
    courseId: z.coerce.number(),
    isCompletingCourse: booleanQuery.optional(), // 커리큘럼에서 모든 사이클이 끝나서 (60개 사이클 완료), 이제 막 커리큘럼이 끝났을때와, 이전 커리큘럼 결과를 구분할 때 사용됨.
  }),
  '/cycle/list': z.object({
    courseId: z.coerce.number(),
    currentCycleId: z.coerce.number().optional(),
  }),
  '/cycle/report': z.object({
    cycleId: z.coerce.number(),
    isCompletingCycle: booleanQuery.optional(),
  }),
  '/diagnosis-report': queryStateQuery.and(
    z.object({
      /**
       * If isCompletingCycle is true, a full-width CTA button is shown at the bottom of the page,
       * instead of the close button on the top.
       */
      isCompletingCycle: booleanQuery.optional(),
      referrer: z.enum(['course', 'analytics', 'onboarding']).optional(),
      tab: z.enum(['analytics', 'explanation']).optional(),
    })
  ),
  '/event/list': z.object({
    tab: z.enum(['active', 'inactive']).optional(),
  }),
  '/event/detail': z.object({
    promotionId: z.coerce.number(),
  }),
  '/event/flash-deal': z.object({
    referrer: z.enum(['course_cell_report', 'diagnosis_report']).optional(),
  }),
  '/event/flash-deal/l2e': z.object({}),
  '/event/flash-deal/new-user': z.object({
    referrer: z.enum(['timer', 'diagnosis_report']).optional(),
  }),
  '/forgot-password': z.object({}),
  '/learning-cell': queryStateQuery.and(
    z.object({
      cellId: z.coerce.number(),
      unitGroupId: z.coerce.number().optional(),
      filterId: z.coerce.number().optional(),
      currentUnitId: z.coerce.number().optional(),
      /**
       * It should be 'cycle_report' if this page loaded from cycle report'
       * It should be 'self_learning' if this page loaded by clicking a self card in self learning tab
       * It should be 'self_learning_weakness_block' if this page loaded by clicking a weakness block in self learning
       * It should be 'self_learning_ongoing_card' if this page loaded by clicking a ongoing card in self learning
       * It should be 'analytics' if this page loaded from analytics tab
       * It should be 'course' if this page loaded from previous result
       * It should be 'onboarding' if this page loaded from onboarding page
       */
      referrer: z
        .enum([
          'cycle_report',
          'self_learning',
          'self_learning_weakness_block',
          'self_learning_ongoing_card',
          'analytics',
          'course',
          'onboarding',
        ])
        .optional(),
    })
  ),
  '/learning-cell/report': z.object({
    cellId: z.coerce.number(),
    /**
     * 선택학습 2depth에서 진입한 경우 해당 값이 존재
     */
    unitGroupId: z.coerce.number().optional(),
    /**
     * If isSelfQuestionWeaknessBlock is true, questions belong to User's weaknessBlock
     */
    isSelfQuestionWeaknessBlock: booleanQuery.optional(),
    cardIndex: z.coerce.number().optional(),
    /**
     * 문제풀이 완료 후 바로 리포트 페이지에 진입한 경우 true
     * 이미 완료된 문제풀이의 결과를 다시 보기 위해 진입한 경우 false
     */
    isCompletingCell: booleanQuery.optional(),
  }),
  '/learning-cell/crm': z.object({
    contentId: z.string(),
  }),
  '/login': queryStateQuery.and(
    z.object({
      /**
       * Code for Line OAuth
       */
      code: z.string().optional(),
    })
  ),
  '/login/email': queryStateQuery,
  '/login-from-brand': z.object({}),
  '/me': z.object({}),
  '/me/setting': z.object({}),
  '/me/setting/theme': z.object({}),
  '/me/target-score': z.object({}),
  '/me/account': z.object({}),
  '/me/learning-goal': z.object({}),
  '/me/exam-score': z.object({
    /**
     * 수정하고자 하는 시험일자 ex) 24-08-22
     */
    date: z.string().optional(),
    /**
     * 등록/수정모달 표시여부
     */
    isFormOpened: booleanQuery.optional(),
  }),
  '/me/account/name': z.object({}),
  '/offer-group/board': offerGroupCommonQuery,
  '/offer-group/board/list': offerGroupCommonQuery,
  '/offer-group/board/detail': offerGroupCommonQuery.and(
    z.object({offerGroupId: z.coerce.number(), backTo: z.string().optional()})
  ),
  '/offer-group/offer/list': offerGroupCommonQuery.and(z.object({offerGroupId: z.coerce.number()})),
  '/offer-group/order': offerOrderCommonQuery.and(z.object({boardCode: z.string().optional()})),
  '/offer-group/order/complete': offerOrderCommonQuery.and(
    z.object({
      imp_uid: z.string().optional(),
      session_id: z.string().optional(),
      error_msg: z.string().optional(),
    })
  ),
  '/offer-group/order/failure': offerOrderCommonQuery,
  '/offer-group/order/result': offerOrderCommonQuery,
  '/offer-group/order/detail': z.object({
    id: z.coerce.number(),
  }),
  '/offer-group/order/list': z.object({}),
  '/permit/list': z.object({
    tab: z.enum(['pass', 'ticket']).optional(),
  }),
  '/onboarding': queryStateQuery.and(
    z.object({
      step: z.coerce
        .number()
        .refine(value => value >= OnboardingStep.SELECT_DOMAIN && value <= OnboardingStep.PREPARE_DIAGNOSIS)
        .default(OnboardingStep.SELECT_DOMAIN)
        .optional(),
      isCompletingSignup: booleanQuery.optional(),
    })
  ),
  '/onboarding/intro': z.object({}),
  '/order/list': z.object({}),
  '/order/detail': orderCommonQuery,
  '/reset-password': z.object({
    /**
     * Code sent via email.
     */
    token: z.string(),
  }),
  '/review': z.object({
    /**
     * 복습페이지 탭 이름
     * quiz, voca, highlight
     */
    tab: z.enum(['quiz', 'voca', 'highlight']).optional(),
    isBookmarkedOnly: booleanQuery.optional(),
    isMarkedAsUnknown: booleanQuery.optional(),
    displayMode: z.enum(['word', 'meaning', 'both']).optional(),
    highlightColorFilter: arrayParam(highlightColorFilterUnion).optional(),
  }),
  '/self-learning': z.object({
    tab: z.coerce.number().optional(),
    subTab: z.coerce.number().optional(),
  }),
  '/self-learning/group': z.object({
    id: z.coerce.number(),
    tab: z.coerce.number().optional(),
    subTab: z.coerce.number().optional(),
  }),
  '/self-learning/group/direct': z.object({
    stopOnOneDepth: booleanQuery.default('false').optional(),
    contentTypeIndex: z.coerce.number().default(0).optional(),
    unitGroupFilterIndex: z.coerce.number().optional(),
    unitGroupIndex: z.coerce.number().default(0).optional(),
  }),
  '/signup': queryStateQuery.and(
    z.object({
      /**
       * Code for Line OAuth
       */
      code: z.string().optional(),
    })
  ),
  '/signup/sns': queryStateQuery.and(
    z.object({
      request: z.string(),
      email: z.string().optional(),
      name: z.string().optional(),
    })
  ),
  '/signup/email': queryStateQuery,
  '/stripe/subscription/[subscriptionId]': stripeSubscriptionCommonQuery,
  '/subscription-order/detail': subscriptionOrderCommonQuery,
  '/webview-test/shell': z.object({}),
  '/virtual-exam': z.object({
    sessionId: z.coerce.number(),
  }),
  '/virtual-exam/report': z.object({
    sessionId: z.coerce.number(),
    referrer: z.enum(['virtual_exam', 'self']).or(z.null()).default(null).optional(),
    tabIndex: z.coerce.number().default(0).optional(),
    filter: z.string().optional(),
    /**
     * 문제풀이 완료 후 바로 리포트 페이지에 진입한 경우 true
     * 이미 완료된 문제풀이의 결과를 다시 보기 위해 진입한 경우 false
     */
    isCompletingCell: booleanQuery.optional(),
  }),
  '/virtual-exam/report/weakness': z.object({
    sessionId: z.coerce.number(),
    type: z.enum(['LC', 'RC']),
    tag: z.string(),
  }),
  '/l2e': z.object({}),
  '/l2e/payback-request': z.object({}),
  '/l2e/leaderboard': z.object({
    period: z.enum(['current', 'previous']).default('current'),
  }),
  '/feedback': z.object({
    targetId: z.coerce.number(), // canbe courseId, cycleId, cellId
    targetType: z.enum(['course', 'cycle', 'cell']),
    isPreferred: booleanQuery,
  }),
  ...TOEIC_SPEAKING_TYPED_QUERY_METADATA,
} as const;

function arrayParam<T>(param: z.ZodType<T>) {
  return param.or(z.array(param)).transform(value => (Array.isArray(value) ? value : [value]));
}

export type ToeicSpeakingNavigationPath = keyof typeof TOEIC_SPEAKING_TYPED_QUERY_METADATA;
export const ToeicSpeakingNavigationPath = Object.keys(TOEIC_SPEAKING_TYPED_QUERY_METADATA).reduce<{
  [Path in ToeicSpeakingNavigationPath]: Path;
}>((acc, path) => ({...acc, [path]: path}), {} as any);

export type SantaNavigationPath = keyof typeof TYPED_QUERY_METADATA;
export const SantaNavigationPath = Object.keys(TYPED_QUERY_METADATA).reduce<{[Path in SantaNavigationPath]: Path}>(
  (acc, path) => ({...acc, [path]: path}),
  {} as any
);

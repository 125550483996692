import { CourseTypeFromJSON, CourseTypeToJSON, } from './CourseType';
import { LearningCycleCellTypeFromJSON, LearningCycleCellTypeToJSON, } from './LearningCycleCellType';
/**
 * Check if a given object implements the LearningCellExtraInfoCourseLearning interface.
 */
export function instanceOfLearningCellExtraInfoCourseLearning(value) {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "courseType" in value;
    isInstance = isInstance && "learningCellType" in value;
    isInstance = isInstance && "learningCycleId" in value;
    return isInstance;
}
export function LearningCellExtraInfoCourseLearningFromJSON(json) {
    return LearningCellExtraInfoCourseLearningFromJSONTyped(json, false);
}
export function LearningCellExtraInfoCourseLearningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseId': json['courseId'],
        'courseType': CourseTypeFromJSON(json['courseType']),
        'learningCellType': LearningCycleCellTypeFromJSON(json['learningCellType']),
        'learningCycleId': json['learningCycleId'],
    };
}
export function LearningCellExtraInfoCourseLearningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseId': value.courseId,
        'courseType': CourseTypeToJSON(value.courseType),
        'learningCellType': LearningCycleCellTypeToJSON(value.learningCellType),
        'learningCycleId': value.learningCycleId,
    };
}

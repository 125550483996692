import React, {CSSProperties} from 'react';
import {css} from '@emotion/react';
import {fixedForwardRef} from '@app/utils/component';

export type ColumnProps<T extends React.ElementType> = {
  gap?: number;
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  as?: T;
} & React.ComponentPropsWithoutRef<T>;

/**
 * gap, justifyContent, alignItems가 prop으로 노출되어 css prop없이 자식 배치가능.
 * css를 사용하는 경우, css 우선 적용.
 */
const Column = <T extends React.ElementType = 'div'>(
  {children, gap, justifyContent, alignItems, as, ...rest}: ColumnProps<T>,
  ref: React.ForwardedRef<Element>
) => {
  const Component = as ?? 'div';
  return (
    <Component
      ref={ref as any}
      css={css`
        display: flex;
        flex-direction: column;
        ${gap !== undefined ? `gap: ${gap}px;` : ''}
        justify-content: ${justifyContent};
        align-items: ${alignItems};
      `}
      {...rest}>
      {children}
    </Component>
  );
};

export default fixedForwardRef(Column);

/**
 * Check if a given object implements the NextLearningCycleInfo interface.
 */
export function instanceOfNextLearningCycleInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function NextLearningCycleInfoFromJSON(json) {
    return NextLearningCycleInfoFromJSONTyped(json, false);
}
export function NextLearningCycleInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
    };
}
export function NextLearningCycleInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
    };
}

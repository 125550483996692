/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetFeedbackResponseFromJSON, HasSubmittedFeedbackResponseFromJSON, UpsertFeedbackRequestToJSON, UpsertFeedbackResponseFromJSON, } from '../models';
/**
 *
 */
export class FeedbackV1Api extends runtime.BaseAPI {
    /**
     * 작성된 피드백을 조회한다.
     */
    async getFeedbackRaw(requestParameters, initOverrides) {
        if (requestParameters.targetType === null || requestParameters.targetType === undefined) {
            throw new runtime.RequiredError('targetType', 'Required parameter requestParameters.targetType was null or undefined when calling getFeedback.');
        }
        if (requestParameters.targetId === null || requestParameters.targetId === undefined) {
            throw new runtime.RequiredError('targetId', 'Required parameter requestParameters.targetId was null or undefined when calling getFeedback.');
        }
        const queryParameters = {};
        if (requestParameters.targetType !== undefined) {
            queryParameters['targetType'] = requestParameters.targetType;
        }
        if (requestParameters.targetId !== undefined) {
            queryParameters['targetId'] = requestParameters.targetId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/feedback/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeedbackResponseFromJSON(jsonValue));
    }
    /**
     * 작성된 피드백을 조회한다.
     */
    async getFeedback(requestParameters, initOverrides) {
        const response = await this.getFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 클라이언트가 최초 툴팁 표시할지 여부를 알기 위한 확인 API. 유저가 피드백을 남기는게 처음이 아닐 경우를 알기 위해 사용한다. 피드백을 한번도 남기지 않았을때 팝업 같은 안내 문구를 표시한다.
     * 유저가 제출한 피드백이 있는지 여부를 확인한다.
     */
    async hasSubmittedFeedbackRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/feedback/submitted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => HasSubmittedFeedbackResponseFromJSON(jsonValue));
    }
    /**
     * 클라이언트가 최초 툴팁 표시할지 여부를 알기 위한 확인 API. 유저가 피드백을 남기는게 처음이 아닐 경우를 알기 위해 사용한다. 피드백을 한번도 남기지 않았을때 팝업 같은 안내 문구를 표시한다.
     * 유저가 제출한 피드백이 있는지 여부를 확인한다.
     */
    async hasSubmittedFeedback(requestParameters, initOverrides) {
        const response = await this.hasSubmittedFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 `targetType` 및 `targetId`에 대한 피드백이 존재하면 값을 수정하고, 존재하지 않으면 새로 생성한다
     * 피드백을 생성 혹은 수정한다.
     */
    async upsertFeedbackRaw(requestParameters, initOverrides) {
        if (requestParameters.upsertFeedbackRequest === null || requestParameters.upsertFeedbackRequest === undefined) {
            throw new runtime.RequiredError('upsertFeedbackRequest', 'Required parameter requestParameters.upsertFeedbackRequest was null or undefined when calling upsertFeedback.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning/v1/feedback/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertFeedbackRequestToJSON(requestParameters.upsertFeedbackRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpsertFeedbackResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 `targetType` 및 `targetId`에 대한 피드백이 존재하면 값을 수정하고, 존재하지 않으면 새로 생성한다
     * 피드백을 생성 혹은 수정한다.
     */
    async upsertFeedback(requestParameters, initOverrides) {
        const response = await this.upsertFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

import {GetMyNoteCountRequest} from '@santa-web/gen/ssp/messages/toeic/app';
import {VirtualExamReportFilter} from '@app/new-structure/entities/virtual-exam/VirtualExamReport';

import {FeedbackTargetSimpleType} from './features/feedback';
import {StringDate} from './features/preferences/types';
import {HighlightColor} from './features/review/types';
import {ListReviewRequest} from './types/review';

export const getContentQueryKey = (url: string | null | undefined) => {
  return ['content', url];
};

export const getContentInteractionStatesQueryKey = (sessionId: number | null | undefined) => {
  return ['contentInteractionStates', sessionId];
};

export const getContentInteractionStateQueryKey = (contentInteractionStateId: number | null | undefined) => {
  return ['contentInteractionState', contentInteractionStateId];
};

export const getIsValidResetPasswordTokenQueryKey = (token: string): [string, string] => {
  return ['isValidResetPasswordToken', token];
};

export const getLearningCellQueryKey = (id: number | null | undefined) => {
  return ['learningCell', id];
};

export const getLearningCellReportQueryKey = (id: number | null | undefined) => {
  return ['learningCellReport', id];
};

export const getLearningCourseReportQueryKey = (id: number | null | undefined) => {
  return ['learningCourseReport', id];
};

export const getSelfLearningCellIdQueryKey = (contentSelectionId: number) => ['selfLearningCellId', contentSelectionId];
export const getSelfLearningSummaryQueryKey = (): [string] => ['selfLearningSummary'];

export const getSelfLearningContentTypesQueryKey = () => ['selfLearningContentTypes'];
export const getSelfLearningUnitGroupFiltersQueryKey = (contentTypeId: number) => [
  'selfLearningUnitGroupFilters',
  contentTypeId,
];
export const getSelfLearningUnitGroupsQueryKey = (contentTypeId: number, filterId?: number) => [
  'selfLearningUnitGroups',
  contentTypeId,
  filterId,
];
export const getSelfLearningUnitGroupDetailQueryKey = (id: number) => ['selfLearningUnitGroupDetail', id];
export const getSelfLearningUnitGroupAllQuestionQueryKey = (id: number) => ['selfLearningUnitGroupAllQuestion', id];

export const getSelfLearningUnitFiltersQueryKey = (unitGroupId: number) => ['selfLearningUnitFilters', unitGroupId];
export const getSelfLearningUnitsQueryKey = (unitGroupId: number, filterId?: number) => [
  'selfLearningUnits',
  unitGroupId,
  filterId,
];

export const getSelfLearningWeaknessBlocksQueryKey = () => ['selfLearningWeaknessBlocks'];

export const getResourceQueryKey = (id: string | null | undefined) => {
  return ['resource', id];
};

export const getResourcesQueryKey = (ids: string[]) => {
  return ['resources', ids];
};

export const getStripeProductBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getStripeProductBoardQueryKey', boardCode];
};
export const getSubscriptionBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getSubscriptionBoardQueryKey', boardCode];
};

export const getProductBoardQueryKey = (boardCode?: string): [string, string | undefined] => {
  return ['getProductBoardQueryKey', boardCode];
};

export const getProductQueryKey = (productId: string): [string, string] => {
  return ['getProductQueryKey', productId];
};

export const getSubscriptionProductQueryKey = (productId: string): [string, string] => {
  return ['getProductQueryKey', productId];
};

export const getStripeProductQueryKey = (productId: number) => {
  return ['getProductQueryKey', productId] as const;
};

export const getOrderQueryKey = (orderId: string): [string, string] => {
  return ['getOrderQueryKey', orderId];
};

export const getSubscriptionOrderQueryKey = (orderId: string): [string, string] => {
  return ['getSubscriptionOrderQueryKey', orderId];
};

export const getIsSubscriptionOrderAvailableQueryKey = (): [string] => {
  return ['getIsSubscriptionOrderAvailable'];
};

export const getIsOrderAvailableQueryKey = (): [string] => {
  return ['getIsOrderAvailableQueryKey'];
};

export const getStripeSubscriptionListQueryKey = () => {
  return ['getStripeSubscriptionQueryKey'] as const;
};

export const getStripeSubscriptionDetailQueryKey = (id: number) => {
  return ['getStripeSubscriptionDetailQueryKey', id] as const;
};

export const getSubscriptionQueryKey = (): ['getSubscriptionQueryKey'] => {
  return ['getSubscriptionQueryKey'];
};

export const getStripeSubscriptionAvailabilityQueryKey = () => ['getStripeSubscriptionAvailabilityQueryKey'] as const;

export const getListFulfillmentByOrderQueryKey = (orderId: string): ['ListFulfillmentByOrder', string] => {
  return ['ListFulfillmentByOrder', orderId];
};

export const getEnhancedOrderQueryKey = (orderId: string): [string, string] => {
  return ['getEnhancedOrderQueryKey', orderId];
};

export const getOrderFulfillmentsQueryKey = (orderId: string): ['getOrderFulfillments', string] => {
  return ['getOrderFulfillments', orderId];
};

export const getAccessGiftCodeQueryKey = (fulfillmentId: string) => {
  return ['getAccessGiftCodeQueryKey', fulfillmentId];
};

export const getUserQueryKey = (): [string] => ['user'];

export const getUserProfileQueryKey = (): [string] => ['profile'];

export const getOrdersQueryKey = (): [string] => {
  return ['listOrders'];
};

// FIXME: 추후 couponsQuery로 모두 마이그레이션되면 삭제 예정
export const listCouponQueryKeyPrefix = ['listCoupon'];
export type GetListCouponsQueryKey = [(typeof listCouponQueryKeyPrefix)[0], {isAvailableOnly: boolean}];
export const getListCouponsQueryKey = (isAvailableOnly: boolean): GetListCouponsQueryKey => {
  return [listCouponQueryKeyPrefix[0], {isAvailableOnly}];
};

export const getCouponsQueryKey = (filter?: any) => ['coupons', filter] as const;

export const getLearningStatisticsQueryKey = (option: any) => ['learningStatistics', option];

export const getLearningCycleReportQueryKey = (id: number | undefined) => ['learningCycleReport', id];

// TN-16727: 미사용 getEXamDatesQueryKey 삭제
// export const getExamDatesQueryKey = (): [string] => {
//   return ['examDates'];
// };

export const getDiagnosisReportQueryKey = () => ['DiagnosisReport'];
export const getDiagnosisQueryKey = () => ['Diagnosis'];
export const getDiagnosisConfigQueryKey = () => ['DiagnosisConfig'];

export const getListReviewsQueryKey = (request: ListReviewRequest): [string, ListReviewRequest] => ['reviews', request];
export const getCountReviewsQueryKey = (request: ListReviewRequest): [string, ListReviewRequest] => [
  'reviewsCount',
  request,
];

export const getOngoingReviewQuizQueryKey = () => ['OngoingReviewQuiz'] as const;
export const getReviewQuizCountQueryKey = (request: GetMyNoteCountRequest) => ['ReviewQuizCount', request] as const;
export const getReviewQuizInMemoryFilterQueryKey = () => ['ReviewQuizInMemoryFilter'] as const;

export const getCouponBoxSummaryKey = () => ['CouponBoxSummary'];

export const getCISReviewsQueryKey = () => ['CISReviews'];

export const getInboxNotificationQueryKey = () => ['inboxNotification'];

export const getCheckInboxNewNotificationQueryKey = () => ['checkInboxNewNotification'];

export const getMarketingBannerQueryKey = () => ['marketingBanner'];

export const getActiveEventsQueryKey = () => ['getActiveEvents'];
export const getInactiveEventsQueryKey = () => ['getInactiveEvents'];
export const getEventDetailQueryKey = (id: number) => ['getEventDetail', id];

export const getVirtualExamInstanceQueryKey = (sessionId: number) => ['virtualExamInstance', sessionId];

export const getVirtualExamReportQueryKey = (sessionId: number): ['VirtualExamReport', number] => [
  'VirtualExamReport',
  sessionId,
];

export const getVirtualExamUnansweredProblemsQueryKey = (
  sessionId: number
): ['VirtualExamUnansweredProblems', number] => ['VirtualExamUnansweredProblems', sessionId];

export const getVirtualExamReviewQueryKey = (
  sessionId: number,
  filter: VirtualExamReportFilter
): ['VirtualExamReview', number, VirtualExamReportFilter] => ['VirtualExamReview', sessionId, filter];

export const getGtelpStatusQueryKey = () => ['gtelpStatus'];

export const getGtelpProfileQueryKey = () => ['gtelpProfile'];

export const getGtelpContentInfosQueryKey = () => ['gtelpContentInfos'];

export const getGtelpContentAnswerStatesQueryKey = () => ['gtelpContentAnswerStates'];

export const getGtelpResultQueryKey = () => ['gtelpResult'];

export const getKakaoLandingResourceQueryKey = () => ['KakaoLandingResource'];

export const getListHighlightInfosQueryKey = (contentInteractionStateId: number) =>
  ['ListHighlightInfosQueryKey', contentInteractionStateId] as const;

export const getHasSubmittedFeedbackQueryKey = () => ['HasSubmittedFeedbackQueryKey'] as const;

export const getGetUserConfigQueryKey = () => ['GetUserConfigQueryKey'] as const;

export const getListLearningCycleInfosQueryKey = () => ['learningCycleInfos'] as const;

export const getSummarizeContentInteractionStateKey = (cellId: number | null | undefined) =>
  ['summarizeContentInteractionState', cellId] as const;

export const getUnderlineMarkupQueryKey = (cisId: number | null | undefined) => ['underlineMarkup', cisId];

export const getLearningDomainListQueryKey = () => ['learningDomainList'] as const;

export const getChatRoomQueryKey = (contentInteractionStateId: number) =>
  ['chatRoom', contentInteractionStateId] as const;

export const getChatMessagesQueryKey = (chatRoomId: number) => ['chatMessages', chatRoomId] as const;

export const getReplyChatMessageQueryKey = (chatMessageId: number) => ['replyChatMessage', chatMessageId] as const;

export const getSkillAnalyticsQueryKey = () => ['skillAnalytics'] as const;

export const getContentStatisticsQueryKey = (cisId: number) => ['contentStatistics', cisId] as const;

export const getKeyValueQueryKey = (request: {key: string; type: string}) => ['keyValue', request] as const;

export const getSystemConfigQueryKey = (type: string) => ['systemConfig', type] as const;

export const getAppInfoQueryKey = () => ['appInfo'] as const;

export const getSystemConfigAppVersionsQueryKey = (platform?: string) => ['systemConfigAppVersions', platform] as const;

export const getOfferGroupQueryKey = (id: number) => ['getOfferGroup', id] as const;

export const getOfferGroupsQueryKey = (boardCode?: string, isUnderAppReview?: boolean) =>
  ['getOfferGroups', boardCode, isUnderAppReview] as const;

export const getOfferGroupOrderQueryKey = (id: number) => ['getOfferGroupOrder', id] as const;

export const getOfferGroupOrdersQueryKey = () => ['getOfferGroupOrders'] as const;

export const getStripeCustomerPortalUrlQueryKey = (orderId: number) => ['getStripeCustomerPortalUrl', orderId] as const;

export const getLearningGoalKey = () => ['learningGoal'] as const;

export const getPassesQueryKey = () => ['passes'] as const;

export const getMyPassStatusQueryKey = () => ['myPassStatus'] as const;

export const getTicketsQueryKey = () => ['tickets'] as const;

export const getLearningGoalStatusKey = () => ['learningGoalStatus'] as const;

export const getExamScoresQueryKey = () => ['getExamScores'] as const;

export const getExamScoreQueryKey = (targetDate?: StringDate) => ['getExamScore', targetDate] as const;

export const getScoreConfigQueryKey = () => ['getScoreConfig'] as const;

export const getExamDatesQueryKey = (startDate: StringDate, endDate: StringDate) =>
  ['getExamDates', startDate, endDate] as const;

export const getMyReferralInfoQueryKey = () => ['getMyReferralInfo'] as const;

export const getLearningHistoriesQueryKey = (filterItemIds?: number[]) =>
  filterItemIds
    ? (['learningHistories', [...filterItemIds].sort().join(',')] as const)
    : (['learningHistories'] as const);

export const getLearningHistoryFilterQueryKey = () => ['getLearningHistoryFilter'] as const;

export const getMyNoteQuizPermitQueryKey = () => ['getMyNoteQuizPermit'] as const;

export const getMyNoteQuizStatusQueryKey = () => ['getMyNoteQuizStatus'] as const;

export const getMyVocabularyLearningHistoriesQueryKey = (args?: {
  bookmarkedOnly: boolean;
  markedAsKnownCondition?: boolean;
}) =>
  args
    ? (['getMyVocabularyLearningHistories', args.bookmarkedOnly, args.markedAsKnownCondition ?? null] as const)
    : ['getMyVocabularyLearningHistories'];

export const getHighlightsPreviewsQueryKey = (args?: {colorCondition: HighlightColor[]}) =>
  args ? (['getHighlightsPreviews', [...args.colorCondition].sort().join(',')] as const) : ['getHighlightsPreviews'];

export const getGuideURLQueryKey = () => ['guideUrl'] as const;

export const getPointrewardInfoQueryKey = () => ['pointrewardInfo'] as const;

export const getPointrewardSummaryQueryKey = () => ['pointrewardSummary'] as const;

export const getLastWeekLeaderboardQueryKey = () => ['lastWeekLeaderboard'] as const;

export const getCurrentWeekLeaderboardQueryKey = () => ['currentWeekLeaderboard'] as const;
export const getCheckCourseLearningPermitQueryKey = () => ['getCheckCourseLearningPermit'] as const;

export const getLatestCouponRegisteredAtQueryKey = () => ['latestCouponRegisteredAt'] as const;
export const getLatestEventDisplaysAtQueryKey = () => ['latestEventDisplaysAt'] as const;
export const getLatestOfferGroupDisplaysAtQueryKey = () => ['latestOfferGroupDisplaysAt'] as const;

export const getCouponsInfinityQueryKey = (availableCondition: boolean) => ['getCoupons', availableCondition] as const;
export const getAvailablePopupQueryKey = () => ['availablePopup'] as const;

export const getFeedbackQueryKey = (targetId: number, targetType: FeedbackTargetSimpleType) =>
  ['feedback', targetId, targetType] as const;
export const getCheckIfFeedbackIsFirstQueryKey = () => ['checkIfFeedbackIsFirst'] as const;

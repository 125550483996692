/* tslint:disable */
/* eslint-disable */
/**
 * 피드백 대상 타입
 *
 * - **COURSE**: 코스
 * - **LEARNING_CYCLE**: 학습 사이클
 * - **LEARNING_CELL**: 학습 셀
 * @export
 */
export const FeedbackTargetType = {
    COURSE: 'COURSE',
    LEARNING_CYCLE: 'LEARNING_CYCLE',
    LEARNING_CELL: 'LEARNING_CELL'
};
export function FeedbackTargetTypeFromJSON(json) {
    return FeedbackTargetTypeFromJSONTyped(json, false);
}
export function FeedbackTargetTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FeedbackTargetTypeToJSON(value) {
    return value;
}

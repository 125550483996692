/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CourseTypeFromJSON, CourseTypeToJSON, } from './CourseType';
import { FallbackOfferGroupInfoFromJSON, FallbackOfferGroupInfoToJSON, } from './FallbackOfferGroupInfo';
/**
 * Check if a given object implements the Course interface.
 */
export function instanceOfCourse(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isLastSelected" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function CourseFromJSON(json) {
    return CourseFromJSONTyped(json, false);
}
export function CourseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'fallbackOfferGroupInfo': !exists(json, 'fallbackOfferGroupInfo') ? undefined : FallbackOfferGroupInfoFromJSON(json['fallbackOfferGroupInfo']),
        'id': json['id'],
        'isLastSelected': json['isLastSelected'],
        'progressRate': !exists(json, 'progressRate') ? undefined : json['progressRate'],
        'tags': json['tags'],
        'title': json['title'],
        'type': CourseTypeFromJSON(json['type']),
    };
}
export function CourseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'fallbackOfferGroupInfo': FallbackOfferGroupInfoToJSON(value.fallbackOfferGroupInfo),
        'id': value.id,
        'isLastSelected': value.isLastSelected,
        'progressRate': value.progressRate,
        'tags': value.tags,
        'title': value.title,
        'type': CourseTypeToJSON(value.type),
    };
}

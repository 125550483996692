/* tslint:disable */
/* eslint-disable */
/**
 * 피드백 타입
 *
 * - **SATISFIED**: 추천에 만족해요
 * - **UNBALANCE**: 문제의 난이도가 나의 실력과 맞지 않아요!
 * - **UNRELATED**: 나의 취약점과 상관없는 문제인 것 같아요!
 * - **UNCORRELATED**: 어떤 근거로 추천된 문제인지 모르겠어요!
 * - **MOBILE_DIFFICULT**: 모바일 환경에서 해당 문제를 풀기 어려워요!
 * - **OVERLAP**: 중복 문제가 추천됐어요! (중복 문제 라벨링)
 * - **ETC**: 기타
 * @export
 */
export const FeedbackType = {
    UNSPECIFIED: 'UNSPECIFIED',
    SATISFIED: 'SATISFIED',
    UNBALANCE: 'UNBALANCE',
    UNRELATED: 'UNRELATED',
    UNCORRELATED: 'UNCORRELATED',
    MOBILE_DIFFICULT: 'MOBILE_DIFFICULT',
    OVERLAP: 'OVERLAP',
    ETC: 'ETC',
    UNRECOGNIZED: 'UNRECOGNIZED'
};
export function FeedbackTypeFromJSON(json) {
    return FeedbackTypeFromJSONTyped(json, false);
}
export function FeedbackTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FeedbackTypeToJSON(value) {
    return value;
}

import { CourseFromJSON, CourseToJSON, } from './Course';
/**
 * Check if a given object implements the ListCourseResponse interface.
 */
export function instanceOfListCourseResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "courses" in value;
    return isInstance;
}
export function ListCourseResponseFromJSON(json) {
    return ListCourseResponseFromJSONTyped(json, false);
}
export function ListCourseResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courses': (json['courses'].map(CourseFromJSON)),
    };
}
export function ListCourseResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courses': (value.courses.map(CourseToJSON)),
    };
}

/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCycleStatusFromJSON, LearningCycleStatusToJSON, } from './LearningCycleStatus';
import { LearningCycleTypeFromJSON, LearningCycleTypeToJSON, } from './LearningCycleType';
/**
 * Check if a given object implements the LearningCycleInfo interface.
 */
export function instanceOfLearningCycleInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "ordinalNumber" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function LearningCycleInfoFromJSON(json) {
    return LearningCycleInfoFromJSONTyped(json, false);
}
export function LearningCycleInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'latestEstimatedScore': !exists(json, 'latestEstimatedScore') ? undefined : json['latestEstimatedScore'],
        'ordinalNumber': json['ordinalNumber'],
        'status': LearningCycleStatusFromJSON(json['status']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': LearningCycleTypeFromJSON(json['type']),
    };
}
export function LearningCycleInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'latestEstimatedScore': value.latestEstimatedScore,
        'ordinalNumber': value.ordinalNumber,
        'status': LearningCycleStatusToJSON(value.status),
        'title': value.title,
        'type': LearningCycleTypeToJSON(value.type),
    };
}

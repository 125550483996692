/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCycleProficiencyWithTargetFromJSON, LearningCycleProficiencyWithTargetToJSON, } from './LearningCycleProficiencyWithTarget';
import { NextLearningCycleInfoFromJSON, NextLearningCycleInfoToJSON, } from './NextLearningCycleInfo';
import { RelatedOfferGroupInfoFromJSON, RelatedOfferGroupInfoToJSON, } from './RelatedOfferGroupInfo';
/**
 * Check if a given object implements the LearningCycleReportCurriculumInfo interface.
 */
export function instanceOfLearningCycleReportCurriculumInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "courseProgressRate" in value;
    isInstance = isInstance && "courseTitle" in value;
    isInstance = isInstance && "learningCycleProficiencyWithTarget" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function LearningCycleReportCurriculumInfoFromJSON(json) {
    return LearningCycleReportCurriculumInfoFromJSONTyped(json, false);
}
export function LearningCycleReportCurriculumInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseId': json['courseId'],
        'courseProgressRate': json['courseProgressRate'],
        'courseTitle': json['courseTitle'],
        'learningCycleProficiencyWithTarget': LearningCycleProficiencyWithTargetFromJSON(json['learningCycleProficiencyWithTarget']),
        'nextLearningCycleInfo': !exists(json, 'nextLearningCycleInfo') ? undefined : NextLearningCycleInfoFromJSON(json['nextLearningCycleInfo']),
        'relatedOfferGroupInfo': !exists(json, 'relatedOfferGroupInfo') ? undefined : RelatedOfferGroupInfoFromJSON(json['relatedOfferGroupInfo']),
        'title': json['title'],
    };
}
export function LearningCycleReportCurriculumInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseId': value.courseId,
        'courseProgressRate': value.courseProgressRate,
        'courseTitle': value.courseTitle,
        'learningCycleProficiencyWithTarget': LearningCycleProficiencyWithTargetToJSON(value.learningCycleProficiencyWithTarget),
        'nextLearningCycleInfo': NextLearningCycleInfoToJSON(value.nextLearningCycleInfo),
        'relatedOfferGroupInfo': RelatedOfferGroupInfoToJSON(value.relatedOfferGroupInfo),
        'title': value.title,
    };
}

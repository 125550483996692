/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the ConsumedCISCounts interface.
 */
export function instanceOfConsumedCISCounts(value) {
    let isInstance = true;
    isInstance = isInstance && "totalQuestionCount" in value;
    isInstance = isInstance && "totalVocabularyCount" in value;
    return isInstance;
}
export function ConsumedCISCountsFromJSON(json) {
    return ConsumedCISCountsFromJSONTyped(json, false);
}
export function ConsumedCISCountsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalLessonCount': !exists(json, 'totalLessonCount') ? undefined : json['totalLessonCount'],
        'totalQuestionCount': json['totalQuestionCount'],
        'totalVocabularyCount': json['totalVocabularyCount'],
    };
}
export function ConsumedCISCountsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalLessonCount': value.totalLessonCount,
        'totalQuestionCount': value.totalQuestionCount,
        'totalVocabularyCount': value.totalVocabularyCount,
    };
}

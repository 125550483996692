/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetCourseReportResponseFromJSON, } from '../models';
/**
 *
 */
export class CourseReportV1Api extends runtime.BaseAPI {
    /**
     * 대상 코스에 대한 리포트를 조회한다.
     * 코스 리포트를 조회한다.
     */
    async getCourseReportRaw(requestParameters, initOverrides) {
        if (requestParameters.courseId === null || requestParameters.courseId === undefined) {
            throw new runtime.RequiredError('courseId', 'Required parameter requestParameters.courseId was null or undefined when calling getCourseReport.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/course-report/{courseId}`.replace(`{${"courseId"}}`, encodeURIComponent(String(requestParameters.courseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCourseReportResponseFromJSON(jsonValue));
    }
    /**
     * 대상 코스에 대한 리포트를 조회한다.
     * 코스 리포트를 조회한다.
     */
    async getCourseReport(requestParameters, initOverrides) {
        const response = await this.getCourseReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

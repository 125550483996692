/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ConsumedCISCountsFromJSON, ConsumedCISCountsToJSON, } from './ConsumedCISCounts';
import { CourseTypeFromJSON, CourseTypeToJSON, } from './CourseType';
import { DurationFromJSON, DurationToJSON, } from './Duration';
import { EstimatedScoreDifferenceFromJSON, EstimatedScoreDifferenceToJSON, } from './EstimatedScoreDifference';
import { EstimatedTagProficienciesWithTargetFromJSON, EstimatedTagProficienciesWithTargetToJSON, } from './EstimatedTagProficienciesWithTarget';
import { EstimatedTagProficiencyWithPreviousFromJSON, EstimatedTagProficiencyWithPreviousToJSON, } from './EstimatedTagProficiencyWithPrevious';
import { LearningCycleReportCurriculumInfoFromJSON, LearningCycleReportCurriculumInfoToJSON, } from './LearningCycleReportCurriculumInfo';
/**
 * Check if a given object implements the LearningCycleReport interface.
 */
export function instanceOfLearningCycleReport(value) {
    let isInstance = true;
    isInstance = isInstance && "consumedCisCounts" in value;
    isInstance = isInstance && "courseType" in value;
    isInstance = isInstance && "estimatedScoreDifference" in value;
    isInstance = isInstance && "mostIncreasedSkillInformation" in value;
    isInstance = isInstance && "ordinalNumber" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    isInstance = isInstance && "weakChapterInformation" in value;
    return isInstance;
}
export function LearningCycleReportFromJSON(json) {
    return LearningCycleReportFromJSONTyped(json, false);
}
export function LearningCycleReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consumedCisCounts': ConsumedCISCountsFromJSON(json['consumedCisCounts']),
        'courseType': CourseTypeFromJSON(json['courseType']),
        'curriculumInfo': !exists(json, 'curriculumInfo') ? undefined : LearningCycleReportCurriculumInfoFromJSON(json['curriculumInfo']),
        'estimatedScoreDifference': EstimatedScoreDifferenceFromJSON(json['estimatedScoreDifference']),
        'mostIncreasedSkillInformation': EstimatedTagProficiencyWithPreviousFromJSON(json['mostIncreasedSkillInformation']),
        'ordinalNumber': json['ordinalNumber'],
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetFromJSON(json['weakChapterInformation']),
    };
}
export function LearningCycleReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consumedCisCounts': ConsumedCISCountsToJSON(value.consumedCisCounts),
        'courseType': CourseTypeToJSON(value.courseType),
        'curriculumInfo': LearningCycleReportCurriculumInfoToJSON(value.curriculumInfo),
        'estimatedScoreDifference': EstimatedScoreDifferenceToJSON(value.estimatedScoreDifference),
        'mostIncreasedSkillInformation': EstimatedTagProficiencyWithPreviousToJSON(value.mostIncreasedSkillInformation),
        'ordinalNumber': value.ordinalNumber,
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
        'weakChapterInformation': EstimatedTagProficienciesWithTargetToJSON(value.weakChapterInformation),
    };
}

import { CourseReportFromJSON, CourseReportToJSON, } from './CourseReport';
/**
 * Check if a given object implements the GetCourseReportResponse interface.
 */
export function instanceOfGetCourseReportResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "courseReport" in value;
    return isInstance;
}
export function GetCourseReportResponseFromJSON(json) {
    return GetCourseReportResponseFromJSONTyped(json, false);
}
export function GetCourseReportResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseReport': CourseReportFromJSON(json['courseReport']),
    };
}
export function GetCourseReportResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseReport': CourseReportToJSON(value.courseReport),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * 코스 타입
 *
 * - **DEFAULT**: 기본 추천학습 코스
 * - **CURRICULUM**: 커리큘럼 코스
 * @export
 */
export const CourseType = {
    DEFAULT: 'DEFAULT',
    CURRICULUM: 'CURRICULUM'
};
export function CourseTypeFromJSON(json) {
    return CourseTypeFromJSONTyped(json, false);
}
export function CourseTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CourseTypeToJSON(value) {
    return value;
}

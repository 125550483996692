import { ConsumedCISCountsFromJSON, ConsumedCISCountsToJSON, } from './ConsumedCISCounts';
import { DurationFromJSON, DurationToJSON, } from './Duration';
import { EstimatedScoreDifferenceFromJSON, EstimatedScoreDifferenceToJSON, } from './EstimatedScoreDifference';
import { EstimatedTagProficiencyWithPreviousFromJSON, EstimatedTagProficiencyWithPreviousToJSON, } from './EstimatedTagProficiencyWithPrevious';
import { NextCourseInfoFromJSON, NextCourseInfoToJSON, } from './NextCourseInfo';
/**
 * Check if a given object implements the CourseReport interface.
 */
export function instanceOfCourseReport(value) {
    let isInstance = true;
    isInstance = isInstance && "consumedCISCounts" in value;
    isInstance = isInstance && "courseTitle" in value;
    isInstance = isInstance && "estimatedScoreDifference" in value;
    isInstance = isInstance && "mostIncreasedChapterInformation" in value;
    isInstance = isInstance && "mostIncreasedSkillInformation" in value;
    isInstance = isInstance && "nextCourseInfo" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    return isInstance;
}
export function CourseReportFromJSON(json) {
    return CourseReportFromJSONTyped(json, false);
}
export function CourseReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consumedCISCounts': ConsumedCISCountsFromJSON(json['consumedCISCounts']),
        'courseTitle': json['courseTitle'],
        'estimatedScoreDifference': EstimatedScoreDifferenceFromJSON(json['estimatedScoreDifference']),
        'mostIncreasedChapterInformation': (json['mostIncreasedChapterInformation'].map(EstimatedTagProficiencyWithPreviousFromJSON)),
        'mostIncreasedSkillInformation': (json['mostIncreasedSkillInformation'].map(EstimatedTagProficiencyWithPreviousFromJSON)),
        'nextCourseInfo': NextCourseInfoFromJSON(json['nextCourseInfo']),
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
    };
}
export function CourseReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consumedCISCounts': ConsumedCISCountsToJSON(value.consumedCISCounts),
        'courseTitle': value.courseTitle,
        'estimatedScoreDifference': EstimatedScoreDifferenceToJSON(value.estimatedScoreDifference),
        'mostIncreasedChapterInformation': (value.mostIncreasedChapterInformation.map(EstimatedTagProficiencyWithPreviousToJSON)),
        'mostIncreasedSkillInformation': (value.mostIncreasedSkillInformation.map(EstimatedTagProficiencyWithPreviousToJSON)),
        'nextCourseInfo': NextCourseInfoToJSON(value.nextCourseInfo),
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
    };
}

/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the LearningCellReportExtraInfoCourseLearningCurriculumInfo interface.
 */
export function instanceOfLearningCellReportExtraInfoCourseLearningCurriculumInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCycleTitle" in value;
    return isInstance;
}
export function LearningCellReportExtraInfoCourseLearningCurriculumInfoFromJSON(json) {
    return LearningCellReportExtraInfoCourseLearningCurriculumInfoFromJSONTyped(json, false);
}
export function LearningCellReportExtraInfoCourseLearningCurriculumInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCycleTitle': json['learningCycleTitle'],
        'nextLearningCellId': !exists(json, 'nextLearningCellId') ? undefined : json['nextLearningCellId'],
    };
}
export function LearningCellReportExtraInfoCourseLearningCurriculumInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCycleTitle': value.learningCycleTitle,
        'nextLearningCellId': value.nextLearningCellId,
    };
}

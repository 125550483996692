/**
 * Check if a given object implements the RelatedOfferGroupInfo interface.
 */
export function instanceOfRelatedOfferGroupInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "offerGroupBoardCode" in value;
    isInstance = isInstance && "offerGroupId" in value;
    return isInstance;
}
export function RelatedOfferGroupInfoFromJSON(json) {
    return RelatedOfferGroupInfoFromJSONTyped(json, false);
}
export function RelatedOfferGroupInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerGroupBoardCode': json['offerGroupBoardCode'],
        'offerGroupId': json['offerGroupId'],
    };
}
export function RelatedOfferGroupInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerGroupBoardCode': value.offerGroupBoardCode,
        'offerGroupId': value.offerGroupId,
    };
}

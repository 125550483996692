import { UserFromJSON, UserToJSON, } from './User';
/**
 * Check if a given object implements the UpdateMyLastSelectedCourseResponse interface.
 */
export function instanceOfUpdateMyLastSelectedCourseResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    return isInstance;
}
export function UpdateMyLastSelectedCourseResponseFromJSON(json) {
    return UpdateMyLastSelectedCourseResponseFromJSONTyped(json, false);
}
export function UpdateMyLastSelectedCourseResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': UserFromJSON(json['user']),
    };
}
export function UpdateMyLastSelectedCourseResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': UserToJSON(value.user),
    };
}

import { FeedbackTargetTypeFromJSON, FeedbackTargetTypeToJSON, } from './FeedbackTargetType';
import { FeedbackTypeFromJSON, FeedbackTypeToJSON, } from './FeedbackType';
/**
 * Check if a given object implements the UpsertFeedbackRequest interface.
 */
export function instanceOfUpsertFeedbackRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "feedbackType" in value;
    isInstance = isInstance && "isPreferred" in value;
    isInstance = isInstance && "targetId" in value;
    isInstance = isInstance && "targetType" in value;
    return isInstance;
}
export function UpsertFeedbackRequestFromJSON(json) {
    return UpsertFeedbackRequestFromJSONTyped(json, false);
}
export function UpsertFeedbackRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'content': json['content'],
        'feedbackType': FeedbackTypeFromJSON(json['feedbackType']),
        'isPreferred': json['isPreferred'],
        'targetId': json['targetId'],
        'targetType': FeedbackTargetTypeFromJSON(json['targetType']),
    };
}
export function UpsertFeedbackRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'content': value.content,
        'feedbackType': FeedbackTypeToJSON(value.feedbackType),
        'isPreferred': value.isPreferred,
        'targetId': value.targetId,
        'targetType': FeedbackTargetTypeToJSON(value.targetType),
    };
}

/**
 * Check if a given object implements the LearningCycleProficiencyWithTarget interface.
 */
export function instanceOfLearningCycleProficiencyWithTarget(value) {
    let isInstance = true;
    isInstance = isInstance && "courseTargetScore" in value;
    isInstance = isInstance && "estimatedCycleProficiency" in value;
    isInstance = isInstance && "targetCycleProficiency" in value;
    return isInstance;
}
export function LearningCycleProficiencyWithTargetFromJSON(json) {
    return LearningCycleProficiencyWithTargetFromJSONTyped(json, false);
}
export function LearningCycleProficiencyWithTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseTargetScore': json['courseTargetScore'],
        'estimatedCycleProficiency': json['estimatedCycleProficiency'],
        'targetCycleProficiency': json['targetCycleProficiency'],
    };
}
export function LearningCycleProficiencyWithTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseTargetScore': value.courseTargetScore,
        'estimatedCycleProficiency': value.estimatedCycleProficiency,
        'targetCycleProficiency': value.targetCycleProficiency,
    };
}

import React from 'react';

export function withSkeleton<ComponentType, SkeletonType>(
  component: ComponentType,
  skeleton: SkeletonType
): ComponentType & {Skeleton: SkeletonType} {
  return Object.assign(component as any, {Skeleton: skeleton});
}

export function withEmpty<ComponentType, EmptyType>(
  component: ComponentType,
  empty: EmptyType
): ComponentType & {Empty: EmptyType} {
  return Object.assign(component as any, {Empty: empty});
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => JSX.Element
): (props: P & React.RefAttributes<T>) => JSX.Element {
  return React.forwardRef(render as any) as any;
}

/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CourseTypeFromJSON, CourseTypeToJSON, } from './CourseType';
import { LearningCellReportExtraInfoCourseLearningCurriculumInfoFromJSON, LearningCellReportExtraInfoCourseLearningCurriculumInfoToJSON, } from './LearningCellReportExtraInfoCourseLearningCurriculumInfo';
/**
 * Check if a given object implements the LearningCellReportExtraInfoCourseLearning interface.
 */
export function instanceOfLearningCellReportExtraInfoCourseLearning(value) {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "courseType" in value;
    isInstance = isInstance && "learningCycleId" in value;
    isInstance = isInstance && "ordinalNumber" in value;
    return isInstance;
}
export function LearningCellReportExtraInfoCourseLearningFromJSON(json) {
    return LearningCellReportExtraInfoCourseLearningFromJSONTyped(json, false);
}
export function LearningCellReportExtraInfoCourseLearningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseId': json['courseId'],
        'courseType': CourseTypeFromJSON(json['courseType']),
        'curriculumInfo': !exists(json, 'curriculumInfo') ? undefined : LearningCellReportExtraInfoCourseLearningCurriculumInfoFromJSON(json['curriculumInfo']),
        'learningCycleId': json['learningCycleId'],
        'ordinalNumber': json['ordinalNumber'],
    };
}
export function LearningCellReportExtraInfoCourseLearningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseId': value.courseId,
        'courseType': CourseTypeToJSON(value.courseType),
        'curriculumInfo': LearningCellReportExtraInfoCourseLearningCurriculumInfoToJSON(value.curriculumInfo),
        'learningCycleId': value.learningCycleId,
        'ordinalNumber': value.ordinalNumber,
    };
}

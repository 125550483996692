/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CurriculumLearningContentPermitResourceFromJSON, CurriculumLearningContentPermitResourceToJSON, } from './CurriculumLearningContentPermitResource';
import { DownloadableFilePermitResourceFromJSON, DownloadableFilePermitResourceToJSON, } from './DownloadableFilePermitResource';
import { ExternalLinkPermitResourceFromJSON, ExternalLinkPermitResourceToJSON, } from './ExternalLinkPermitResource';
import { GiftCodePermitResourceFromJSON, GiftCodePermitResourceToJSON, } from './GiftCodePermitResource';
import { PermitResourceCaseFromJSON, PermitResourceCaseToJSON, } from './PermitResourceCase';
/**
 * Check if a given object implements the OneOfTicketResources interface.
 */
export function instanceOfOneOfTicketResources(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfTicketResourcesFromJSON(json) {
    return OneOfTicketResourcesFromJSONTyped(json, false);
}
export function OneOfTicketResourcesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'curriculumLearningContent': !exists(json, 'curriculumLearningContent') ? undefined : CurriculumLearningContentPermitResourceFromJSON(json['curriculumLearningContent']),
        'downloadableFile': !exists(json, 'downloadableFile') ? undefined : DownloadableFilePermitResourceFromJSON(json['downloadableFile']),
        'externalLink': !exists(json, 'externalLink') ? undefined : ExternalLinkPermitResourceFromJSON(json['externalLink']),
        'giftCode': !exists(json, 'giftCode') ? undefined : GiftCodePermitResourceFromJSON(json['giftCode']),
        'oneOfCase': PermitResourceCaseFromJSON(json['oneOfCase']),
        'recommendedLearningContent': !exists(json, 'recommendedLearningContent') ? undefined : json['recommendedLearningContent'],
    };
}
export function OneOfTicketResourcesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'curriculumLearningContent': CurriculumLearningContentPermitResourceToJSON(value.curriculumLearningContent),
        'downloadableFile': DownloadableFilePermitResourceToJSON(value.downloadableFile),
        'externalLink': ExternalLinkPermitResourceToJSON(value.externalLink),
        'giftCode': GiftCodePermitResourceToJSON(value.giftCode),
        'oneOfCase': PermitResourceCaseToJSON(value.oneOfCase),
        'recommendedLearningContent': value.recommendedLearningContent,
    };
}

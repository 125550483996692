import {NextRouter, useRouter} from 'next/router';
import React from 'react';
import {z} from 'zod';
import {SantaNavigationPath, TYPED_QUERY_METADATA} from '@app/types/navigation';

type Push = NextRouter['push'];
type Replace = NextRouter['replace'];
export type NextUrl = Parameters<Push>[0];
export type TypedUrl<
  Url extends NextUrl,
  Path extends SantaNavigationPath,
  Query extends z.infer<(typeof TYPED_QUERY_METADATA)[Path]>
> = Url extends string ? Path : Omit<Url, 'pathname' | 'query'> & {pathname?: Path; query?: Query};

const getIsString = (str: any): str is string => typeof str === 'string';

export const convertTypedUrlToFullUrl = <
  Path extends SantaNavigationPath,
  Query extends z.infer<(typeof TYPED_QUERY_METADATA)[Path]>
>(
  url: TypedUrl<NextUrl, Path, Query>
): string => {
  const concatOrigin = (exceptOrigin: string) => {
    const origin = window.location.origin;
    return `${origin}${exceptOrigin}`;
  };
  if (getIsString(url)) {
    return concatOrigin(url);
  } else if (url.query) {
    return concatOrigin(
      `${url.pathname}?${Object.entries(url.query).reduce(
        (acc, [key, value]) =>
          `${acc}&${Array.isArray(value) ? value.map(v => `${key}=${v}`).join('&') : `${key}=${value}`}`,
        ''
      )}`
    );
  } else {
    return concatOrigin(url.pathname ?? '');
  }
};

export const convertTypedUrlToNextUrl = <
  Path extends SantaNavigationPath,
  Query extends z.infer<(typeof TYPED_QUERY_METADATA)[Path]>
>(
  _url: TypedUrl<NextUrl, Path, Query>
): NextUrl => {
  let url: NextUrl = _url;
  if (getIsString(_url)) {
    // noop
  } else if (_url.query) {
    url = {
      ..._url,
      query: Object.entries(_url.query).reduce((acc, [key, value]) => {
        if (value != null && value !== '') {
          return {...acc, [key]: Array.isArray(value) ? value : String(value)};
        }
        return acc;
      }, {}),
    };
  }
  return url;
};

export const useTypedSearchParams = <
  Path extends SantaNavigationPath,
  Query extends z.infer<(typeof TYPED_QUERY_METADATA)[Path]>
>(
  path: Path,
  onError?: (e: QueryParamError) => void
) => {
  const {query} = useRouter();
  try {
    return React.useMemo(() => {
      const filteredQuery = Object.entries(query).reduce((acc, [key, value]) => {
        if (value != null && value !== '') {
          return {...acc, [key]: value};
        }
        return acc;
      }, {});

      return TYPED_QUERY_METADATA[path].parse(filteredQuery) as Query;
    }, [path, query]);
  } catch (e) {
    const error = new QueryParamError(e.message, e);
    onError?.(error);
    throw error;
  }
};

export const useTypedRouter = () => {
  const {push: _push, replace: _replace, ...router} = useRouter();

  const push = React.useCallback(
    <
      UrlPath extends SantaNavigationPath,
      AsPath extends SantaNavigationPath,
      UrlQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[UrlPath]>,
      AsQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[AsPath]>
    >(
      url: TypedUrl<NextUrl, UrlPath, UrlQuery>,
      as?: TypedUrl<NextUrl, AsPath, AsQuery>,
      options?: Parameters<Push>[2]
    ): ReturnType<Push> => {
      return _push(convertTypedUrlToNextUrl(url), as && convertTypedUrlToNextUrl(as), options);
    },
    [_push]
  );

  const replace = React.useCallback(
    <
      UrlPath extends SantaNavigationPath,
      AsPath extends SantaNavigationPath,
      UrlQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[UrlPath]>,
      AsQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[AsPath]>
    >(
      url: TypedUrl<NextUrl, UrlPath, UrlQuery>,
      as?: TypedUrl<NextUrl, AsPath, AsQuery>,
      options?: Parameters<Push>[2]
    ): ReturnType<Replace> => {
      return _replace(convertTypedUrlToNextUrl(url), as && convertTypedUrlToNextUrl(as), options);
    },
    [_replace]
  );

  return React.useMemo(
    () => ({push, replace, externalPush: _push, externalReplace: _replace, ...router}),
    [_push, _replace, push, replace, router]
  );
};

class QueryParamError extends Error {
  cause?: unknown;
  constructor(message: string, e: unknown) {
    super(message);
    this.cause = e;
    this.name = 'QueryParamError';
  }
}

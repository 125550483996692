import { CourseTypeFromJSON, CourseTypeToJSON, } from './CourseType';
/**
 * Check if a given object implements the NextCourseInfo interface.
 */
export function instanceOfNextCourseInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function NextCourseInfoFromJSON(json) {
    return NextCourseInfoFromJSONTyped(json, false);
}
export function NextCourseInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'type': CourseTypeFromJSON(json['type']),
    };
}
export function NextCourseInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'type': CourseTypeToJSON(value.type),
    };
}

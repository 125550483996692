/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningCycleResponseFromJSON, ListLearningCycleInfosResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningCycleV1Api extends runtime.BaseAPI {
    /**
     * 대상 학습 사이클에 속한 `AIBlock` 중 ID가 `aiBlockId`와 일치하는 것을 찾아 해당 `AIBlock`을 읽음 처리한다.\\ 읽음 처리된 `AIBlock`은 이후 해당 `AIBlock`을 포함한 `LearningCycle`의 정보를 불러올 때 `aiBlocks` 목록에 나타나지 않는다.
     * `AIBlock`을 읽음 처리한다.
     */
    async consumeAIBlockRaw(requestParameters, initOverrides) {
        if (requestParameters.aiBlockId === null || requestParameters.aiBlockId === undefined) {
            throw new runtime.RequiredError('aiBlockId', 'Required parameter requestParameters.aiBlockId was null or undefined when calling consumeAIBlock.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cycles/ai-blocks/{aiBlockId}`.replace(`{${"aiBlockId"}}`, encodeURIComponent(String(requestParameters.aiBlockId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 대상 학습 사이클에 속한 `AIBlock` 중 ID가 `aiBlockId`와 일치하는 것을 찾아 해당 `AIBlock`을 읽음 처리한다.\\ 읽음 처리된 `AIBlock`은 이후 해당 `AIBlock`을 포함한 `LearningCycle`의 정보를 불러올 때 `aiBlocks` 목록에 나타나지 않는다.
     * `AIBlock`을 읽음 처리한다.
     */
    async consumeAIBlock(requestParameters, initOverrides) {
        await this.consumeAIBlockRaw(requestParameters, initOverrides);
    }
    /**
     * 주어진 ID에 해당하는 학습 사이클을 조회한다.\\ 만약 해당 학습 사이클이 `COURSE_LEARNING` 타입이고 학습 셀이 배정되지 않은 상태이면 학습 셀을 배정한 후 응답한다.
     * 학습 사이클을 조회한다.
     */
    async getLearningCycleRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getLearningCycle.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cycles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningCycleResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 해당하는 학습 사이클을 조회한다.\\ 만약 해당 학습 사이클이 `COURSE_LEARNING` 타입이고 학습 셀이 배정되지 않은 상태이면 학습 셀을 배정한 후 응답한다.
     * 학습 사이클을 조회한다.
     */
    async getLearningCycle(requestParameters, initOverrides) {
        const response = await this.getLearningCycleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자 및 사용자가 선택한 학습 도메인에 대한 학습 사이클의 요약 정보를 조회한다.\\ 만약 가장 최근 학습 사이클이 완료된 상태이면 새로운 학습 사이클을 생성한 뒤에 조회 요청을 처리한다.\\ `courseId`가 존재하는 경우 해당 코스에 속한 학습 사이클을 조회한다.
     * 학습 사이클의 요약 정보를 조회한다.
     */
    async listLearningCycleInfosRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listLearningCycleInfos.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.courseId !== undefined) {
            queryParameters['courseId'] = requestParameters.courseId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-cycles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListLearningCycleInfosResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자 및 사용자가 선택한 학습 도메인에 대한 학습 사이클의 요약 정보를 조회한다.\\ 만약 가장 최근 학습 사이클이 완료된 상태이면 새로운 학습 사이클을 생성한 뒤에 조회 요청을 처리한다.\\ `courseId`가 존재하는 경우 해당 코스에 속한 학습 사이클을 조회한다.
     * 학습 사이클의 요약 정보를 조회한다.
     */
    async listLearningCycleInfos(requestParameters, initOverrides) {
        const response = await this.listLearningCycleInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

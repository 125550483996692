import { FeedbackFromJSON, FeedbackToJSON, } from './Feedback';
/**
 * Check if a given object implements the UpsertFeedbackResponse interface.
 */
export function instanceOfUpsertFeedbackResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "feedback" in value;
    return isInstance;
}
export function UpsertFeedbackResponseFromJSON(json) {
    return UpsertFeedbackResponseFromJSONTyped(json, false);
}
export function UpsertFeedbackResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'feedback': FeedbackFromJSON(json['feedback']),
    };
}
export function UpsertFeedbackResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'feedback': FeedbackToJSON(value.feedback),
    };
}

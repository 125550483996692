/* tslint:disable */
/* eslint-disable */
/**
 * 학습 사이클의 셀 생성 및 완료 현황
 *
 * - **EMPTY**: 학습 셀이 생성되지 않음
 * - **CELL_CREATED**: 학습 셀이 생성되었으나 전부 완료되지는 않음
 * - **COMPLETED**: 학습 셀이 전부 완료됨
 * - **LOCKED**: 학습 사이클이 잠겨있음
 * @export
 */
export const LearningCycleStatus = {
    EMPTY: 'EMPTY',
    CELL_CREATED: 'CELL_CREATED',
    COMPLETED: 'COMPLETED',
    LOCKED: 'LOCKED'
};
export function LearningCycleStatusFromJSON(json) {
    return LearningCycleStatusFromJSONTyped(json, false);
}
export function LearningCycleStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCycleStatusToJSON(value) {
    return value;
}
